import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

const InfoJson = () => {
    const { index } = useParams();
    //console.log(JSON.parse(localStorage.info_json.key(3)))
    const info_json = JSON.parse(localStorage.info_json);
    console.log(info_json[index]);
    return (
        
        
        JSON.stringify(info_json[index])
        
    );
};

export default InfoJson;