import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { axiosClient } from "./apiClient";
import { Container, Row, Col, Form, Button, Modal} from "react-bootstrap";
//import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import "./App.css";
import * as Icon from 'react-bootstrap-icons';
import "leaflet/dist/leaflet.css";
import "leaflet/dist/leaflet.js";
import "react-leaflet-fullscreen/dist/styles.css";
import $ from 'jquery';
import { useTranslation } from "react-i18next";
import { useIdleTimer } from "react-idle-timer";

import {QRCodeSVG} from 'qrcode.react';

//import './uu-system-style.css';
//import { getExhibition, getExhibitions } from "./api";

const Exhibition = () => {
  const { id } = useParams();
  const { group_id } = useParams();
  //const { language } = useParams();
  const navigate = useNavigate();
  const [exhibition, setExhibition] = useState([]);
  const [exhibitionItem, setExhibitionItem] = useState([]);
  //const [language, setLanguage] = useState();
  //const [activeIndex, setActiveIndex] = useState([]);
  const [index, setIndex] = useState(0);
  const [direction, setDirection] = useState(null);
  const [show, setShow]=React.useState(false);
  const location = useLocation();
  //const language = location.state.language;
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  
  if(language != "swe" && language != "eng"){
    console.log("Byter språk pga fel...");
    i18n.changeLanguage('swe');
  }
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
    //console.log("selectedIndex " + selectedIndex);
  };
  const ref = useRef(null);

  const onPrevClick = () => {
    ref.current.prev();
  };
  const onNextClick = () => {
    ref.current.next();
  };
  const reset = () => {
    //setExhibitionItem([]);
    navigate(`/exhibition/${id}`);
    window.location.reload(false);
  }

  const onIdle = () => {
    reset();
  }
  const onActive = (event) => {
    
  }
  const idleTimer = useIdleTimer({ onIdle, onActive, timeout: 120000 });

  let isMounted = true;


  const fetchExhibitItem = async () => {
    try {
      const { data } = await axiosClient.get(`kua/api/v1/resources/itemgroupsummary/${group_id}/${language}`); //axios.get(`https://jsonplaceholder.typicode.com/posts/${id}`);
      if (data) {
        //console.log("itemgroupsummary group_id = " + group_id);
        //console.log(data);
        window.scrollTo(0, 0);
        setExhibitionItem(data);
        document.getElementById("items").childNodes.forEach(function(item){
          item.classList.remove("show");
        });
        document.getElementById("items").childNodes[0].setAttribute( 'class', 'row collapse show' );
      } else {
      }
      setIndex(0);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if(!group_id){
      return;
    }
    fetchExhibitItem(exhibitionItem, index);
  }, [group_id]);


  const openExhibitItem2 = (e) => {
    setIndex(0);
    const newValue = e;
    //console.log("exhibitionItem = ");
    //console.log(exhibitionItem); 
    //console.log(i18n.language);
    navigate(`/exhibition/${id}/item/${newValue}`, {state: {language: i18n.language}});
  };
  const openIIIF = (e) => {
    const url = new URL(e);
    const url_params = new URLSearchParams(url.search);
    navigate(`/zoom`, {state: {iiif_url: url_params.get('iiif'), previous: location.pathname, language: language}});
  }
  const fetch = async () => {
    try {
      const { data } = await axiosClient.get(`kua/api/v1/resources/showcasesummary/${id}`)//axios.get(`https://jsonplaceholder.typicode.com/posts/${id}`);
      if (isMounted) {
        //console.log("First item = " + data[0].item_group_id);
        //console.log("showcasesummary");
        //console.log(data);
        setExhibition(data);
        if(!group_id){
          openExhibitItem2(data[0].item_group_id);
        }
      } else {
      }
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    setExhibitionItem([]);
    fetch();
  }, [id]);

  const soundOrVision = (path, id) => {
    let media;
    let extension = path.split('.').pop();
    switch (extension) {
      case "mp3":
        media = "sound";
        break;
      default:
        media = "img";
    }
    return media;
  }

  const eventListenerCollapse = (id)  => {
    //console.log(id);
    let elements = document.querySelectorAll(".collapse");
    let elementsArray = Array.prototype.slice.call(elements);
    elementsArray.forEach(function(elem){
      elem.addEventListener("hidden.bs.collapse", function(){
        //console.log("hidden.bs.collapse triggered!");
        if(document.querySelector("#" + elem.id + " :nth-child(2)").nodeName == "AUDIO"){
          document.querySelector("#" + elem.id + " :nth-child(2)").pause();
          document.querySelector("#" + elem.id + " :nth-child(2)").currentTime = 0;
        }
      });
    });
  }

  const dataTargets = (length, index) => {
    let button = "";
    if(length > 1) { //Knappar behövs bara om det är mer än ett item 
      if (index + 1 === length) {//0+1 och längd är 1
        return <Button className="btn-primary_" data-bs-toggle="collapse" data-bs-target={"#id_" + (index) + ", #id_" + (index-1)} aria-label="{t('previous')}">{t('previous')}</Button>;
      } else if(index === 0) {
        return <Button className="btn-primary_" data-bs-toggle="collapse" data-bs-target={"#id_" + (index) + ", #id_" + (index+1)} aria-label="{t('next')}">{t('next')}</Button>;
      } else {
        return <><Button className="btn-primary_" data-bs-toggle="collapse" data-bs-target={"#id_" + (index) + ", #id_" + (index - 1)} aria-label="{t('previous')}">{t('previous')}</Button><Button className="btn-primary_" data-bs-toggle="collapse" data-bs-target={"#id_" + (index) + ", #id_" + (index + 1)} aria-label="{t('next')}">{t('next')}</Button></>;
      }
    }
    return button;
  }

  function showcasesSelectCallback(e){ 
    e.preventDefault();
     const l = e.target.value;
     i18n.changeLanguage(l);
     //Byter endast språk i UI. Refactor: Fixa så att texter/strängar från API injiceras i i18next?
     //Istället för att hämta specifikt språk för itemgroupsummary hämta alla språk och visa/göm baserat på i18n? fult men kan funka?
     //navigate(`/exhibition/${id}/item/${group_id}`, {state: {language: i18n.language}});
     //openExhibitItem2(group_id);
     //Gör en knapp som visar eng med value svenska om engleska är språk annars tvärtom mha ternary stuffz {"swe" ? 'eng' : 'swe'}
     window.location.reload(false); //FULT MEN FUNKAR
  }

  function switchLanguage(e){
    i18n.changeLanguage(e.target.value);
    window.location.reload(false);
  }

  return (
    <article>
      <Container id="items" style={{"paddingTop": "30px"}}>
        {exhibitionItem?.map((el, index, arr) => (
          <Row className={(index === 0) ? "row collapse show" : "row collapse"} id={"id_" + index} key={index}>
          <Col md={6} className="text-center">
          <div style={{display: "block"}} className="btn-group" role="group">
            {
            (soundOrVision(el.medium_url, el.item_id) == "sound")
              ? ""
              : <Button className="btn-primary_" onClick={() => openIIIF(el.iiif_url)} id="zoom" aria-label="{t('zoom')}"><Icon.ZoomIn /> {t('zoom')}</Button>
            }
            {dataTargets(arr.length, index)}
          </div>
            {
              (soundOrVision(el.medium_url, el.item_id) == "sound")
              ? <audio style={{ margin: "10px", width: "100%"}} key={el.item_id} id={"audio_" + el.item_id} src={el.medium_url} controls/>
              : <img className="img-fluid" style={{ margin: "10px", "maxHeight": "500px"}} key={el.item_id} src={el.medium_url} onClick={() => openIIIF(el.iiif_url)} />
            }
            <div dangerouslySetInnerHTML={{ __html: el.item_title }} style={{"paddingTop": "10px"}}/>
          </Col>
          <Col md={6} className="overflow-auto" style={{"maxHeight": "500px"}}>
          <QRCodeSVG style={{marginLeft: "20px", display: "none"}} className="float-end" value={'https://exhibitions.ub.uu.se/exhibition/' + id} />
            <Button style={{marginLeft: "20px"}} className="float-end btn-primary_" onClick={() => setShow(true)}>
              <Icon.QuestionLg />
            </Button>
            <Modal
              show={show}
              onHide={() => setShow(false)}
              dialogClassName="help"
              aria-labelledby="example-custom-modal-styling-title"
            >
              <Modal.Header closeButton closeVariant="white">
                <Modal.Title id="example-custom-modal-styling-title">
                  {t('help')} 
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p dangerouslySetInnerHTML={{__html: t('help_body', { joinArrays: " <br /><br />"})}} />
              </Modal.Body>
            </Modal>
            <Button className="float-end btn-primary_" onClick={switchLanguage} value={i18n.language == "swe" ? 'eng' : 'swe'}>{i18n.language == "swe" ? t('english') : t('swedish')}</Button>
            <div dangerouslySetInnerHTML={{ __html: el.item_text }} />
          </Col>
          {eventListenerCollapse(el.item_id)}
          </Row>
        ))}
      </Container>
      <Container className="text-center">
      {exhibition?.map((el) => (
          <span key={el.item_group_sort_order}>{el.item_group_sort_order} <img style={{height: "60px", margin: "10px"}} key={el.item_group_id} src={el.thumbnail_small_url} onClick={() => openExhibitItem2(el.item_group_id)} value={el.item_group_id}/></span>
      ))}
      </Container>
    </article>
  );
};

export default Exhibition;