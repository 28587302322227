
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';


// Importing translation files

import translationEN from "./locales/en/en.json";
import translationSV from "./locales/sv/sv.json";


//Creating object with the variables of imported translation files
const resources = {
  eng: {
    translation: translationEN,
  },
  swe: {
    translation: translationSV,
  },
};

//i18N Initialization

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    resources,
    fallbackLng:"eng",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;