import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { axiosClient } from "./apiClient";
import { Container, Row, Col, Form, Button} from "react-bootstrap";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
//import * as L from "react-leaflet";
import L from 'leaflet';
//import L from 'leaflet-iiif';
import 'leaflet-iiif';
//import { FullscreenControl } from "react-leaflet-fullscreen";
//import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import "./App.css";
import "leaflet/dist/leaflet.css";
import "leaflet/dist/leaflet.js";
import "react-leaflet-fullscreen/dist/styles.css";
import "leaflet-iiif/leaflet-iiif.js";
import { Viewer } from "react-iiif-viewer";
import $ from 'jquery';
import { useTranslation } from "react-i18next";
import { useIdleTimer } from "react-idle-timer";
//import { getSystemErrorMap } from "util";
//import { from } from "responselike";
//import './uu-system-style.css';
//import { getExhibition, getExhibitions } from "./api";

const Zoom = () => {
    const { id } = useParams();
    const { group_id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    //const [map, setMap] = useState([]);
    const viewer_iiif_json = location.state.iiif_url;
    const language = location.state.language;
    const { t, i18n } = useTranslation();

    const reset = () => {
        //setExhibitionItem([]);
        //navigate(`/exhibition/${id}`);
        //window.location.reload(false);
        previous(location.state.previous);
      }
    
      const onIdle = () => {
        reset();
      }
      const onActive = (event) => {
        
      }
      const idleTimer = useIdleTimer({ onIdle, onActive, timeout: 120000 });



    const mapStart = () => {
        //viewer_iiif_json = "https://www.alvin-portal.org/iipsrv/iipsrv.fcgi?IIIF=/mnt/data/alvin/tif/158000/500/alvin-record_158502-ATTACHMENT-0001.ptif/info.json";
        viewer_iiif_json = location.state.iiif_url;
        //var L = document.getElementById("#map");
        var map = L.map('map', {
            center: [0, 0],
            zoom: 0,
          });
          L.tileLayer('https://www.alvin-portal.org/iipsrv/iipsrv.fcgi?IIIF=/mnt/data/alvin/tif/158000/500/alvin-record_158502-ATTACHMENT-0001.ptif/info.json').addTo(map);
          //L.tileLayer('http://localhost:5050/map?map=https://www.alvin-portal.org/iipsrv/iipsrv.fcgi?IIIF=/mnt/data/alvin/tif/158000/500/alvin-record_158502-ATTACHMENT-0001.ptif/info.json').addTo(map);
    }

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        mapMarkup();
        setIsLoaded(true);
    }, []);



    const mapMarkup = () => {
        return (
            <><Viewer width="100%" height="100%" iiifUrl={viewer_iiif_json} /><Button className="btn-primary_" onClick={() => previous(location.state.previous)} id="zoomback">{t('back')}</Button></>
        )
    }
    const previous = (e) => {
        navigate(`${e}`, {state: {language: language}});
    };
    return (
        <div>{mapMarkup()}</div>
    )
}
export default Zoom;
{/*

<div id="map" style={{ height: "500px" }}></div><Button onClick={mapStart}>TEST</Button>

<div ref={mapRef} id="map"></div>

<MapContainer center={[0, 0]} zoom={0} style={{ height: "400px" }}>
            <TileLayer
                crossOrigin=""
                url={img} />
        </MapContainer>
*/}