import React from "react";
//import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  useParams,
  useLocation
} from "react-router-dom";
import ScreenProxy from "./screenproxy.js";
import Exhibitions from "./exhibitions.js";
import Exhibition from "./exhibition.js";
import Zoom from "./zoom.js";
import MultiViewerIIIF from "./multiviewer.js";
import InfoJson from "./infojson.js";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import "./App.css";
import { Container, Row, Col, Form, Button, Navbar} from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { getIp } from "./apiClient";
import { get } from "jquery";
import CarouselViewer from "./carouselviewer.js";

//import bg from "./clouds.png";

//const App = () => {

class App extends React.Component {
  
  //const { key } = useLocation();
  //console.log(key);
  //const { exhibitionId } = useParams();
  //console.log(exhibitionId);
  
  constructor(props) {
    super(props);
    this.state = { exhibitionId: "" };
    //const ip = getIp.get('/ip');
    //console.log(ip);
    
  }
  // this.checkAge is passed as the callback to setState
  updateState = (value) => {
    this.setState({ exhibitionId: value}, this.setExhibitionId);
  };

  setExhibitionId = (event) => {
    const exhibitionId = event.target.value;
    return exhibitionId;
  };
  //getIp.get(/ip).then((response)=>{console.log(response)});
  
  
  /*state = { test: "INITIAL STATE FROM APP" };
  update = test => {
     this.setState({test:test })// or with es6 this.setState({name})
  }*/

  render() {
    console.log(process.env.NODE_ENV);
    console.log("react api endpoint: " + process.env.REACT_APP_API_ENDPOINT);
    console.log("REACT APP VERSION: " + process.env.REACT_APP_VERSION);
    console.log("REACT_APP_TEMA_1:" + process.env.REACT_APP_TEMA_1);
    //document.body.style.backgroundImage = `url('${bg}')`;
    console.log(document.body.style.backgroundImage);
    //console.log(window.ENV.SCREEN);
    //const { t } = this.props;
    const { t, i18n } = this.props;
    
    /*console.log("tjoho i render");
    getIp.get(`/ip`, {})
    .then(res => {
        console.log("tjoho");
        const ip = res.data;
        console.log(ip);
    })
    .catch((error) => {
        console.log(error)
    })*/
    return (
      <><Routes>
          <Route exact path="/" element={<><Exhibitions /></>} />
          <Route exact path="/screen/:id" element={<><ScreenProxy /></>} />
          <Route exact path="/multiviewer/:id" element={<><MultiViewerIIIF /></>} />
          <Route exact path="/infojson/:index" element={<><InfoJson /></>} />
          <Route path="/carousel/:id" element={<><CarouselViewer /></>} />
          <Route path="/carousel/:id/item/:group_id" element={<><CarouselViewer /></>} />
          <Route path="/exhibition/:id" element={<><Exhibition /></>} />
          <Route path="/exhibition/:id/item/:group_id" element={<><Exhibition /></>} />
          <Route exact path="/exhibition/:id/item/:group_id/zoom" element={<><Zoom /></>} />
          <Route path="/zoom" element={<><Zoom /></>} />
        </Routes></>
    );
  }
};

export default withTranslation()(App);
/* 
<Navbar fixed="bottom" expand="lg" variant="light" bg="light">
        <Container>
          <Navbar.Brand href="#">{this.props.t('digital_exhibitions')}</Navbar.Brand>
        </Container>
      </Navbar>
*/