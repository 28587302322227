import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
//import axios from "axios";
import { axiosClient } from "./apiClient";
import { getExhibition, getExhibitions } from "./api";
import { Container, Row, Col, Form, Button} from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Exhibitions = () => {
//console.log("API: " + process.env.REACT_APP_API_ENDPOINT);
//class Exhibitions extends React.Component {
  const [exhibitions, setExhibitions] = useState([]);
  const [exhibitionId, setExhibitionId] = useState([]);
  //const [language, setLanguage] = useState([]);
  
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  function showcasesSelectCallback(e){ 
    e.preventDefault();
     // standard web api change event
     const eid = e.target.exhibition.value;
     const language = e.target.language.value;
     i18n.changeLanguage(language);
     // update state based on input field changes
     //this.setState({ id: newValue });
     setExhibitionId(eid);
     //console.log("exhibitionId = " + exhibitionId);
     if(eid != "") { navigate(`/exhibition/${eid}`, {state: {language: language}}); }
     //this.getExhibition(newValue);
     //setData("This is data from Parent Component to the Child Component.");
  }


/*
  //let isMounted = true;
  const fetch = async () => {
    try {
      const { data } = await axiosClient.get(`/kua/api/v1/resources/showcases/`)//axios.get("https://jsonplaceholder.typicode.com/posts");
      //console.log(data);
      if (isMounted) {
        console.log("isMounted");
        setExhibitions(data);
      } else {
        console.log("not mounted");
      }
      //console.log(exhibitions);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    fetch();
  }, []);
*/
  //async fetch(){
  //  return await axiosClient.get(`/kua/api/v1/resources/showcases/`);
  //}

  const fetch = async () => {
    //setLoading(true);
    try {
      const response = await axiosClient.get(`/kua/api/v1/resources/showcases/`);
      //this.setState({ exhibitions: response.data });
      setExhibitions(response.data);
      //console.log(this.exhibitions);
    } catch (error) {
      console.log(error);
      //this.setState({ error: true });
    } finally {
      //setLoading(false);
    }
  }


  useEffect(() => {
    fetch();
  }, []);

    return (
      <>
      <Form onSubmit={showcasesSelectCallback}>
        <Form.Select name="language">
          <option value="">{t('chose_language')}</option>
          <option value="swe">{t('swedish')}</option>
          <option value="eng">{t('english')}</option>
        </Form.Select>
        <Form.Select name="exhibition">
          <option value="">{t('chose_exhibition')}</option>
          {exhibitions?.map((el) => <option key={el.id} value={el.id}>{el.name} ({el.id} {el.comment})</option>)}
        </Form.Select>
        <Button className="btn-primary_" type="submit">{t('start')}</Button>
        </Form>
      </>
    );

};

export default Exhibitions;
/*
{exhibitions?.map((el) => (
  <article key={el.id}>
    <Link to={`exhibition/${el.id}`}>
      <h1>{el.name} ({el.comment})</h1>
    </Link>
  </article>
))}
*/
