import axios from "axios";
const axiosClient = axios.create({
  //baseURL: `http://localhost:5050`,
  //baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
  //baseURL: window.ENV.API_ENDPOINT,
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    'Accept': 'application/json',
    //'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  }
});
const axiosClientNode = axios.create({
  //baseURL: `http://localhost:5050`,
  //baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
  baseURL: window.ENV.NODE,
  headers: {
    'Accept': 'application/json',
    //'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  }
});
const getIp = axios.create({
  //baseURL: `http://localhost:5050`,
  //baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
  baseURL: window.ENV.SERVER,
  headers: {
    'Accept': 'application/json',
    //'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  }
});
axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let res = error.response;
    console.log(error);
    if (res.status == 401) {
      // window.location.href = "https://example.com/login";
      console.log(res.status);
    }
    console.error("Looks like there was a problem. Status Code: " + res.status);
    return Promise.reject(error);
  }
);
export {axiosClient, getIp};
export default {
  axiosClient
};