import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { axiosClient } from "./apiClient";
import { axiosClientNode } from "./apiClient";
import { Container, Row, Col, Form, Button, Modal} from "react-bootstrap";
//import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import "./App.css";
import * as Icon from 'react-bootstrap-icons';
import "leaflet/dist/leaflet.css";
import "leaflet/dist/leaflet.js";
import "react-leaflet-fullscreen/dist/styles.css";
import $ from 'jquery';
import { useTranslation } from "react-i18next";
import { getExhibition } from "./api";
import { MultiViewer } from "react-iiif-viewer";

//import './uu-system-style.css';
//import { getExhibition, getExhibitions } from "./api";

const MultiViewerIIIF = () => {
    //Anropa med /multiviewer/:id där id används vid uppslag av showcasesummary, dvs item_groups
    //kör först 
    //http://localhost:5050/kua/api/v1/resources/showcasesummary/37
    //För varje item_group_id kör med rätt språk
    //http://localhost:5050/kua/api/v1/resources/itemgroupsummary/154/eng
    //I loopen skapa 
    //iiifUrls={[iiif_url, iiif_url...]}
  const { id } = useParams();
  const { group_id } = useParams();
  //const { language } = useParams();
  const navigate = useNavigate();
  const [exhibition, setExhibition] = useState([]);
  const [exhibitionItem, setExhibitionItem] = useState([]);
  const [groupIds, setGroupIds] = useState([]);
  const [multiViewerContent, setMultiViewerContent] = useState([]);
  //const [language, setLanguage] = useState();
  //const [activeIndex, setActiveIndex] = useState([]);
  const [index, setIndex] = useState(0);
  const [direction, setDirection] = useState(null);
  const [show, setShow]=React.useState(false);
  const location = useLocation();
  //const language = location.state.language;
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  //i18n.changeLanguage('swe');
  //console.log(language);
  if(language != "swe" && language != "eng"){
    console.log("Byter språk pga fel...");
    //i18n.changeLanguage('swe');
  }


  const [IIIFImages, setIIIFImages] = useState([]);
  const getIIIFImages = (d) => {
    console.log(d);
    const endpoints = [];
    const iiif_urls = [];
    d.map((exhibit) => (
        endpoints.push(`kua/api/v1/resources/itemgroupsummary/${exhibit.item_group_id}/${language}`)
    ));
    console.log(endpoints);
    Promise.all(endpoints.map((endpoint) => axiosClient.get(endpoint))).then( res => {
        console.log(res);
        res.map((a) => {
            //iiif_urls.push(a.data[0].iiif_url);
            //console.log(a.data[0].iiif_url);
            const q = new URL(a.data[0].iiif_url);
            const y = new URL(new URLSearchParams(q.search).get("iiif"));
            //console.log(new URLSearchParams(q.search).get("iiif"));
            //console.log(y);
            const z = new URL(window.ENV.API_ENDPOINT + y.pathname);
            //console.log(z);
            //iiif_urls.push(new URLSearchParams(q.search).get("iiif"));
            iiif_urls.push(`${z.href}`);
        })
        setIIIFImages(iiif_urls);
        console.log(iiif_urls);
        console.log("resolved");
        getInfoJson(iiif_urls);
        //console.log(IIIFImages);
        return iiif_urls;
    });
  }



  const getInfoJson = async (iiif_urls) => {
    const info_json_array = [];
    Promise.all(iiif_urls.map((url) => axiosClient.get(url))).then( res => {
      console.log(res);
      res.map((a) => {
        console.log(a.data);
        info_json_array.push(a.data);
      })
      localStorage.setItem("info_json", JSON.stringify(info_json_array));
      console.log(JSON.parse(localStorage.info_json));
    });
  }





  const item_group_ids = [];
  const IIIF = [];
  const getExhibitionItemGroupIds = async () => {
    console.log("getExhibitionItemGroupIds");
    const { data } = await axiosClient.get(`kua/api/v1/resources/showcasesummary/${id}`);
    //data.map((exhibit) => (
        //console.log(exhibit.item_group_id)
        //item_group_ids.push(exhibit.item_group_id)
    //));
    console.log(data);
    //setGroupIds(item_group_ids);
    //console.log(groupIds);
    //getIIIFs(item_group_ids);
    //return console.log("getExhibitionItemGroupIds är klar! " + JSON.stringify(data));
    return getIIIFImages(data);
    //return getIIIFs(data);
  }

  useEffect(() => {
    getExhibitionItemGroupIds();
    console.log(IIIFImages);
  }, []);

  const getIIIF = async (gid) => {
    console.log("getIIIF");
    const { data } = await axiosClient.get(`kua/api/v1/resources/itemgroupsummary/${gid}/${language}`);
    console.log(data);
    data.map((obj) => (
        //console.log(exhibit.item_group_id)
        IIIF.push(obj.iiif_url)
    ));
  }

  /*useEffect(() => {
    getExhibitionItemGroupIds();
    setMultiViewerContent(IIIF);
  }, []);*/

  const getIIIFs = (data) => {
    console.log("getIIIFs");
    console.log(item_group_ids);
    console.log(Array.isArray(item_group_ids));
    data.map((exhibit) => (
        //console.log(exhibit.item_group_id)
        item_group_ids.push(exhibit.item_group_id)
    ));
    console.log(item_group_ids);
    item_group_ids.forEach((item, index) => {
        getIIIF(item)
    });
    console.log(IIIF);
    setMultiViewerContent(IIIF);
    console.log(multiViewerContent);
  }

  const mv = () => {
    console.log(IIIFImages);
    console.log("isArray? = " + Array.isArray(IIIFImages));
    console.log(IIIFImages.join());
    console.log(IIIFImages.toString());
    console.log("length = " + IIIFImages.length);
    //return <p keys={multiViewerContent.join()}>TEST</p>;
    return IIIFImages;
    //return <MultiViewer iiifUrls={IIIFImages.toString()}/>
  }

  const y = () => {
      return <MultiViewer iiifUrls="[`${IIIFImages}`]"/>    
  }
  
  return (
    <div>
    {
        <MultiViewer iiifUrls={mv()}/>
    }
    
    {
        <MultiViewer iiifUrls={["https://www.alvin-portal.org/iipsrv/iipsrv.fcgi?IIIF=/mnt/data/alvin/tif/85000/400/alvin-record_85478-ATTACHMENT-0001.ptif/info.json", 
        "https://www.alvin-portal.org/iipsrv/iipsrv.fcgi?IIIF=/mnt/data/alvin/tif/85000/400/alvin-record_85478-ATTACHMENT-0001.ptif/info.json",
        "https://www.alvin-portal.org/iipsrv/iipsrv.fcgi?IIIF=/mnt/data/alvin/tif/85000/400/alvin-record_85478-ATTACHMENT-0001.ptif/info.json",
        "https://www.alvin-portal.org/iipsrv/iipsrv.fcgi?IIIF=/mnt/data/alvin/tif/85000/400/alvin-record_85478-ATTACHMENT-0001.ptif/info.json"]}/>
    }

    </div>
  );
};

export default MultiViewerIIIF;

//https://www.alvin-portal.org/iipsrv/iipsrv.fcgi?IIIF=/mnt/data/alvin/tif/85000/400/alvin-record_85478-ATTACHMENT-0001.ptif/info.json
//https://www.alvin-portal.org/iipsrv/iipsrv.fcgi?IIIF=/mnt/data/alvin/tif/158000/500/alvin-record_158502-ATTACHMENT-0001.ptif/info.json
//https://bruno.ub.uu.se/iiifviewer/?iiif=https://bruno.ub.uu.se/kua_pictures/ill_22_Victoria_fotograferar.jpg/info.json